import { http, authHeader } from '@/http-commons';

class RayaDataService {
    create( raya ){
        return http.post('api/v1/raya/create', raya, { headers : authHeader() });
    }
    table( params ){
        return http.get('api/v1/raya/table', { params, headers : authHeader() });
    }
    show( rayaId ){
        return http.get(`api/v1/raya/get/${rayaId}`, {headers : authHeader() });
    }
    showLastByCompany( empresaId ){
        return http.get(`api/v1/raya/getLastByIdEmpresa/${empresaId}`, {headers : authHeader() });
    }
    update( rayaId, raya ){
        return http.put(`api/v1/raya/update/${rayaId}`, raya, { headers : authHeader() });
    }
}
export default new RayaDataService();