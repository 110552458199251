import { http, authHeader } from '@/http-commons';

class RayaDetalleDataService {
    tableByRaya( rayaId, params ){
        return http.get(`api/v1/rayaDetalle/tableByIdRaya/${rayaId}`, { params, headers : authHeader() });
    }
    show( rayaDetalleId ){
        return http.get(`api/v1/rayaDetalle/show/${rayaDetalleId}`, {headers : authHeader() });
    }
    update( rayaDetalleId, rayaDetalle ){
        return http.put(`api/v1/rayaDetalle/update/${rayaDetalleId}`, rayaDetalle, { headers : authHeader() });
    }
    changeToPaid( rayaDetalleId ){
        return http.put(`api/v1/rayaDetalle/changeToPaid/${rayaDetalleId}`, null, { headers : authHeader() });
    }
    downloadRayaDetallesExcelByIdRaya( rayaId ){
        return http.get(`api/v1/rayaDetalle/downloadExcelByIdRaya/${ rayaId }`, { headers: authHeader(), responseType: 'blob' });
    }
    downloadRayaDetallesExcelById( rayaDetalleId ){
        return http.get(`api/v1/rayaDetalle/downloadExcelById/${ rayaDetalleId }`, { headers: authHeader(), responseType: 'blob' });
    }
}
export default new RayaDetalleDataService();