<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">

                <!-- Title and search -->
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva raya
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar lista de raya por fecha de inicio" single-inline hide-details
                        @keydown.enter="searchRaya">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>

                <!-- Table -->
                <v-card-text>
                    <v-data-table dense :headers="filteredHeaders" :items="rayas" sort-by="idRaya" class="elevation-1" 
                    :loading="loading" :server-items-length="totalElements" :items-per-page="itemsPerPage"
                    :page.sync="page" @page-count="totalPages = $event" 
                    hide-default-footer>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="downloadRaya(item)">fas fa-download</v-icon>
                            <v-icon small class="mr-2" @click="showModalDetails(item)">fas fa-eye</v-icon>
                        </template>

                    </v-data-table>
                </v-card-text>

                <!-- Pagination -->
                <v-card-actions>
                    <PaginationNumber :itemsPerPage.sync="itemsPerPage" :pageCount.sync="totalPages"
                    :totalElements.sync="totalElements" :page.sync="page" :initialize.sync="initialize" />
                </v-card-actions>

            </v-card>
        </v-col>

        <ModalNew :dialog.sync="dialog" :initialize.sync="initialize" :handleModal="handleModal" :empresas.sync="empresas" v-if="dialog"/>
        <ModalDetails :dialogDetails.sync="dialogDetails" :handleModalDetails="handleModalDetails" :rayaSelected.sync="rayaSelected"  v-if="dialogDetails"/>
    </v-row>
</template>
<script>
    import { getParamsPagination } from '@/utils/pagination';
    import PaginationNumber from '@/components/shared/PaginationNumber.vue';
    import EmpresaDataService from '@/service/catalogo/empresa/empresaDataService';
    import RayaDataService from '@/service/raya/RayaDataService';

    import ModalNew from "./NuevaRaya.vue";
    import ModalDetails from "./MostrarDetalles.vue";
    import RayaDetalleDataService from '@/service/raya/RayaDetalleDataService';
    import Columns from '@/components/shared/Columns.vue';

    export default {
        name: "ListaRaya",
        components: { ModalNew, ModalDetails, PaginationNumber, Columns },
        created() {
            this.initialize();
            this.getAllCompanies();
        },
        data() {
            return {
                headers: [
                    { text: "Detalle", value: "actions", sortable: false, groupable: false, show: true },
                    { text: "ID", align: " d-none", sortable: false, value: "id", visible: false, groupable: false, show: false },
                    { text: "Fecha de inicio", value: "fechaInicio", sortable: false, groupable: false, show: true },
                    { text: "Fecha de fin", value: "fechaFin", sortable: false, groupable: false, show: true },
                    { text: "Empresa", value: "empresa.empresa", sortable: false, groupable: false, show: true },
                ],
                search: "",
                page: 1,
                totalPages: 0,
                itemsPerPage: 25,
                totalElements: 0,
                loading: false,
                
                rayas: [],
                empresas: [],
                rayaSelected: {},

                dialog: false,
                dialogDetails: false,
            }
        },
        methods: {
            // Download Methods
            downloadRaya(item) {
                RayaDetalleDataService.downloadRayaDetallesExcelByIdRaya(item.id)
                .then(resp => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `lista_de_raya_del_${item.fechaInicio}_al_${item.fechaFin}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: 'Error al obtener los datos' + err.response.data,
                        showConfirmButton: false,
                        timer: 3500
                    });
                });
            },

            // Table Methods
            searchRaya() {
                this.page = 1;
                this.initialize({ page: this.page, itemsPerPage: this.itemsPerPage });
            },
            initialize( options = {} ) {
                this.loading = true;
                this.itemsPerPage = options.itemsPerPage;
                const params = getParamsPagination({ options, search: this.search });

                RayaDataService.table(params).then( ({ data }) => {
                    const { rows, totalElements, totalPages, currentPage } = data;
                    this.rayas = rows;
                    this.totalElements = totalElements;
                    this.page = currentPage + 1 ;
                    this.totalPages = totalPages;
                }).catch( err => {
                    this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
                }).finally(() => {
                    this.loading = false;
                });
            },

            // Modal Methods
            handleModal() { this.dialog = !this.dialog; },
            handleModalDetails() { this.dialogDetails = !this.dialogDetails; },
            showModalDetails( item ) {
                this.rayaSelected = item;
                this.dialogDetails = true;
            },

            // Async Methods
            async getAllCompanies(){ this.empresas =  await EmpresaDataService.lstEmpresa().then( resp => resp.data ) || [] }
        },
        computed: {
            filteredHeaders() { return this.headers.filter(header => header.show); }
        }
    }
</script>
