<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition">
            <v-card outlined shaped class="elevation-4">

                <ValidationObserver ref="refRaya" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="saveRaya">     
  
                        <!-- Title -->
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>

                            <v-toolbar-title>
                                <div class="text-h6 font-weight-bold white--text">
                                    Nueva lista de raya
                                </div>
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-toolbar-items>
                                <v-btn color="primary" type="submit" tile dark align="right"  :disabled="invalid || !validated || employees.length == 0 || paymentThisWeek">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon> Guardar 
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>

                        <!-- Company and period -->
                        <v-card-text>
                            <v-row>
                                <v-col cols="9" md="8" sm="12">
                                    <v-col cols="12" sm="12" md="8">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                            <v-autocomplete :items="empresas" item-text="empresa" item-value="id" v-model="raya.idEmpresa"
                                            label="Empresa" required clearable outlined prepend-inner-icon="fas fa-id-card-alt"
                                            hint="Seleccione la empresa" :error-messages="errors" :success="valid">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                </v-col>

                                <v-col cols="12" md="4" sm="12">
                                    <v-alert class="calendario" color="primary" icon="fas fa-calendar-check" prominent type="success">
                                        {{ period }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <!-- List of employees -->
                        <v-card-text v-if="raya.idEmpresa > 0">
                            <v-row v-if="!paymentThisWeek">

                                <v-col cols="12" class="text-center text-h5 font-weight-bold primary--text text-uppercase" v-if="hasEmployees">
                                    Lista de empleados
                                </v-col>
                                <v-col cols="12" v-if="hasEmployees">
                                    <AddDetails :employees.sync="employees" @addDetails="addDetails">
                                    </AddDetails>
                                </v-col>

                                <!-- Loading -->
                                <v-col cols="12" class="text-center" v-if="loadingEmployees">
                                    <v-progress-circular
                                        :size="50"
                                        :width="5"
                                        color="primary"
                                        indeterminate>
                                    </v-progress-circular>
                                </v-col>

                                <!-- No employees -->
                                <v-col cols="12" v-if="!hasEmployees && !loadingEmployees">
                                    <v-alert border="left" colored-border type="info" elevation="2" class=" mt-2 text-center font-weight-bold text-h5">
                                        No hay empleados para mostrar
                                    </v-alert>
                                </v-col>
                            </v-row>
                            
                            <v-row v-else>
                                <v-col cols="12">
                                    <v-card-text class="red--text">
                                        <v-icon color="red">fas fa-exclamation-triangle</v-icon>
                                        Ya se genero la lista de RAYA de la semana actual: <strong>{{ period }}</strong>, favor de verificar.
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-form>
                </ValidationObserver>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import { getWeeklyPeriod, getFirstDayOfWeek, getLastDayOfWeek } from "@/utils/DateUtil";
    import EmpleadoDataService from '@/service/empleado/empleadoDataService';
    import RayaDataService from '@/service/raya/RayaDataService';

    import AddDetails from './AgregarDetalles.vue';

    export default {
        name: "NuevaRaya",
        props: {
            dialog: { type: Boolean, default: false },
            handleModal: { type: Function },
            initialize: { type: Function },
            empresas: { type: Array, default: [] }
        },
        components:{ AddDetails },
        data () {
            return {
                raya: {
                    id:     null,
                    fechaInicio: null,
                    fechaFin: null,
                    idEmpresa: null,
                    listaRayaDetalles: [],
                },
                
                employees:   [],
                detailsList: [],
                lastPayroll: {},

                loadingEmployees: false,
                hasEmployees: false,
                paymentThisWeek : false
            }
        },
        methods: {
            // Methods for add details
            addDetails( details ){
                this.detailsList = details;
            },

            // Methods for validations
            checkIfPaymentThisWeek(){
                this.paymentThisWeek = getFirstDayOfWeek().toFormat('dd/MM/yyyy') == this.lastPayroll.fechaInicio && getLastDayOfWeek().toFormat('dd/MM/yyyy') == this.lastPayroll.fechaFin;
            },

            // Methods for save data
            saveRaya(){
                this.$refs.refRaya.validate().then( isValid => {
                    if( isValid ){
                        this.$swal.fire({ 
                            icon: 'warning', 
                            title: 'Guardar lista de raya', 
                            text: "¿Desea registrar la lista de raya?, este proceso es irreversible", 
                            showCancelButton: true, 
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Si, confirmar!" }).then( (resp) => {

                            if ( resp.isConfirmed ) {
                                // Set raya data to save
                                this.raya.fechaInicio = getFirstDayOfWeek().toFormat('yyyy-MM-dd');
                                this.raya.fechaFin = getLastDayOfWeek().toFormat('yyyy-MM-dd');
                                this.raya.listaRayaDetalles = this.removeExtraFieldsInDetailsList();

                                RayaDataService.create( this.raya ).then( rsp => {
                                    this.initialize();
                                    this.handleModal();
                                    this.$swal.fire({ icon: 'success', title: 'Lista de raya registrada', text: rsp.data.message, showConfirmButton: false, timer: 2500 });
                                }).catch( err => {
                                    this.$swal.fire({icon: 'error', title: 'Lista de raya no registrada, validar campos', text: err.response.data, showConfirmButton: false, timer: 2500 });
                                });
                            }
                        });
                    }
                }).catch( err => {
                    this.$swal.fire({ icon: 'error', title: 'Lista de raya no registrada, validar campos', text: err, showConfirmButton: false });
                })
            },

            // Remove extra fields Methods
            removeExtraFieldsInDetailsList(){
                return this.detailsList.map( (employee) => {
                    return {
                        idEmpleado: employee.idEmpleado,
                        bonos: parseFloat(employee.bonos),
                        comentarios: employee.comentarios,
                    }
                })
            },
            removeExtraFieldsInEmployees(employeesData){
                return employeesData.filter(employee => employee.diasTrabajadosEstaSemana > 0).map(employee => {
                    return {
                        nombreCompleto: employee.nombreCompleto,
                        idEmpleado: employee.id,
                        bonos: 0,
                        comentarios: '',
                        diasTrabajadosEstaSemana: employee.diasTrabajadosEstaSemana,
                        salarioDia: employee.salarioDia
                    }
                });
            },

            // Async Methods
            async getAllEmployeesByCompany(idCompany){ 
                this.loadingEmployees = true;
                this.hasEmployees = false;

                await EmpleadoDataService.allByCompany(idCompany).then(({ data }) => {
                    this.employees = this.removeExtraFieldsInEmployees(data ?? []);
                }).finally(() => {
                    this.loadingEmployees = false;
                    this.hasEmployees = this.employees.length > 0;
                });
            },
            async findLastPaymentByCompany(idCompany){
                await RayaDataService.showLastByCompany(idCompany).then(res => {
                    this.lastPayroll = res.data || {};

                    if (this.lastPayroll) {
                        this.checkIfPaymentThisWeek();
                    }

                    if (!this.paymentThisWeek) {
                        this.getAllEmployeesByCompany(idCompany);
                    }
                });
            },
        },
        computed: {
            period() {
                return getWeeklyPeriod();
            }
        },
        watch: {
            'raya.idEmpresa': function( newValue ){
                if( !newValue ) {
                    this.lastPayroll = {};
                    this.employees = [];
                    this.loadingEmployees = false;
                    this.hasEmployees = false;
                    return;
                }

                this.findLastPaymentByCompany(newValue);
            }
        }
    }

</script>