<template>
    <v-row justify="center">
        <v-dialog v-model="dialogDetails" persistent transition="dialog-bottom-transition">
            <v-card class="elevation-4">

                <!-- Title -->
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click.native="handleModalDetails">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        <div class="text-h6 font-weight-bold white--text">
                            Detalles del {{ period }}
                        </div>
                    </v-toolbar-title>
                </v-toolbar>

                <!-- Search -->
                <v-card-title>
                    <v-spacer></v-spacer>

                    <v-col cols="12" md="6" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar por nombre del empleado" single-inline hide-details
                        @keydown.enter="searchRaya">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>

                <!-- Table -->
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="detailsList" sort-by="idRaya" class="elevation-1" 
                    :server-items-length="totalElements" :items-per-page="itemsPerPage"
                    :page.sync="page" @page-count="totalPages = $event" 
                    hide-default-footer>

                        <template v-slot:[`item.estatus`]="{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="item.estatus == 'ESTATUS_PAGADO' ? 'green' : 'red'"
                                small dense filled label> {{ item.estatus == 'ESTATUS_PAGADO' ? 'Pagado' : 'Por pagar' }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="downloadDetail(item)">fas fa-download</v-icon>
                            <v-icon small class="mr-2" @click="registerPayment(item)" v-if="!item.fechaPago">fas fa-money-bill-1</v-icon>
                        </template>

                    </v-data-table>
                </v-card-text>

                <!-- Pagination -->
                <v-card-actions>
                    <PaginationNumber :itemsPerPage.sync="itemsPerPage" :pageCount.sync="totalPages"
                    :totalElements.sync="totalElements" :page.sync="page" :initialize.sync="initialize" />
                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import { getWeeklyPeriod } from '@/utils/DateUtil';
    import { getParamsPagination } from '@/utils/pagination';
    import PaginationNumber from '@/components/shared/PaginationNumber.vue';
    import RayaDetalleDataService from '@/service/raya/RayaDetalleDataService';

    export default {
        name: "MostrarDetalles",
        props: {
            dialogDetails: { type: Boolean, default: false },
            handleModalDetails: { type: Function },
            rayaSelected: { type: Object, default: {} },
        },
        components: { PaginationNumber },
        created() {
            this.initialize();
        },
        data() {
            return {
                headers: [
                    { text: "Detalle", value: "actions", sortable: false },
                    { text: "id", align: " d-none", sortable: false, value: "id", visible: false },
                    { text: "Empleado", value: "empleado.nombreCompleto", sortable: false },
                    { text: "Salario diario", value: "salarioDiario", sortable: false },
                    { text: "Dias trabajados", value: "diasTrabajados", sortable: false },
                    { text: "Bonos", value: "bonos", sortable: false },
                    { text: "Deducciones", value: "deducciones", sortable: false },
                    { text: "Neto a pagar", value: "netoPagar", sortable: false },
                    { text: "Comentarios", value: "comentarios", sortable: false },
                    { text: "Estatus", value: "estatus", sortable: false },
                    { text: "Fecha de pago", value: "fechaPago", sortable: false },
                ],
                search: "",
                page: 1,
                totalPages: 0,
                itemsPerPage: 25,
                totalElements: 0,

                detailsList: [],
            }
        },
        methods: {
            // Download Methods
            downloadDetail( item ) {
                RayaDetalleDataService.downloadRayaDetallesExcelById(item.id)
                .then(resp => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `lista_de_raya_${ item.empleado.nombre }_del_${ this.rayaSelected.fechaInicio }_al_${ this.rayaSelected.fechaFin }.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: 'Error al obtener los datos' + err.response.data,
                        showConfirmButton: false,
                        timer: 3500
                    });
                });
            },

            // Table Methods
            searchRaya() {
                this.page = 1;
                this.initialize({ page: this.page, itemsPerPage: this.itemsPerPage });
            },
            initialize( options = {} ) {
                const params = getParamsPagination({ options, search: this.search });
                this.itemsPerPage = options.itemsPerPage;

                RayaDetalleDataService.tableByRaya(this.rayaSelected.id, params).then( ({ data }) => {
                    const { rows, totalElements, totalPages, currentPage } = data;
                    this.detailsList = rows;
                    this.totalElements = totalElements;
                    this.page = currentPage + 1 ;
                    this.totalPages = totalPages;
                }).catch( err => {
                    this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 2500 });
                });
            },

            // Payment Method
            registerPayment( item ) {
                this.$swal.fire({ 
                    icon: 'warning', 
                    title: 'Registra pago', 
                    text: `¿Desea registrar el pago de ${item.empleado.nombreCompleto}?, este proceso es irreversible`, 
                    showCancelButton: true, 
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, confirmar!" }).then( (resp) => {

                    if ( resp.isConfirmed ) {
                        RayaDetalleDataService.changeToPaid( item.id ).then( rsp => {
                            this.initialize();
                            this.$swal.fire({ icon: 'success', title: 'Pago registrado', text: 'Pago registrado exitosamente', showConfirmButton: false, timer: 2500 });
                        }).catch( err => {
                            this.$swal.fire({icon: 'error', title: 'Pago no registrado, intente nuevamente', text: 'Error al registrar el pago', showConfirmButton: false, timer: 2500 });
                        });
                    }
                });
            }
        },
        computed: {
            period: function() {
                return getWeeklyPeriod();
            }
        }
    }
</script>
