<template>
    <v-row>
        <v-col cols="12">
            <v-simple-table fixed-header height="auto" dense class="elevation-1 mt-2">
                <template v-slot:default>

                    <!-- Header -->
                    <thead class="text-center primary">
                        <tr>
                            <th class="text-left">Empleado</th>
                            <th class="text-left">Datos</th>
                            <th class="text-left">Bonos</th>
                            <th class="text-left">Comentarios</th>
                            <th class="text-left">Pago neto</th>
                            <th class="text-left">Acciones</th>
                        </tr>
                    </thead>

                    <!-- Body -->
                    <tbody>
                        <tr v-for="(item) in detailsList" :key="item.idEmpleado">
                            <td>
                                <v-autocomplete class="mt-2" :items="[item]" item-text="nombreCompleto" item-value="idEmpleado"
                                filled outlined required solid disabled
                                :value="item.idEmpleado">
                                </v-autocomplete>
                            </td>

                            <td>
                                <p>
                                    Dias laborados: {{ item.diasTrabajadosEstaSemana }}<br/>
                                    Salario al dia: <span class="green--text">$</span>{{ item.salarioDia }}
                                </p>
                            </td>

                            <td>
                                <v-text-field :value="item.bonos" v-model="item.bonos" type="number" solo
                                class="font-weight-medium text-h8 mt-2 green--text"
                                persistent-hint prefix="$" min="0">
                                </v-text-field>
                            </td>

                            <td>
                                <v-text-field :value="item.comentarios" v-model="item.comentarios" type="text" solo
                                class="font-weight-medium text-h8 mt-2 green--text"
                                persistent-hint>
                                </v-text-field>
                            </td>

                            <td>
                                <p class="text-h6">
                                    <span class="green--text">$</span>{{ getNetSalary(item) }}
                                </p>
                            </td>

                            <td>
                                <v-row align="center" justify="center" class="mb-1">
                                    <span class="group pa-1">
                                        <v-icon small class="mx-1 pa-1" left color="red darken-1" @click="deleteElement(item)">fas fa-trash-alt</v-icon>
                                    </span>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>

                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "AgregarDetalles",
        props: {
            employees: { type: Array, default: [] },
            addDetails: { type: Function }
        },
        created() {
            this.detailsList = this.employees;
        },
        data() {
            return {
                detailsList: []
            }
        },
        methods: {
            // Method for calculate net salary
            getNetSalary(item) {
                return item.bonos ? (item.salarioDia * item.diasTrabajadosEstaSemana) + Number(item.bonos) : (item.salarioDia * item.diasTrabajadosEstaSemana);
            },

            // Method for delete element
            deleteElement(item) {
                this.$swal.fire({
                    title: "Eliminar de la lista de raya",
                    text: `¿Estas seguro de eliminar a ${ item.nombreCompleto } de la lista de raya?, No se podra revertir la decision!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, eliminar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const itemIndex = this.detailsList.findIndex(detail => detail === item);
        
                        if (itemIndex !== -1) {
                            this.detailsList.splice(itemIndex, 1);
                        }
                    }
                });
            },

            // Method for emit data to father
            emitData(data){
                this.$emit('addDetails', data);
            },
        },
        watch: {
            detailsList: {
                handler(newValue) {
                    this.emitData(newValue);
                },
                deep: true
            },
        }
    }
</script>